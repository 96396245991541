import { HostLogType } from './../constants/host-log-types.enum';
import ClassSessionInterface from '../interfaces/classSessionInterface';
import { logger } from './logger';
import WebviewSettingsEditModeInterface from '../interfaces/webview-settings-edit-mode.interface';
import { isCpVerAtLeast } from '../helpers/utils';
import { AiQuizSlideInterface } from '../interfaces/aiQuiz.interface';

const webviewMessenger = {
    sendUserLoaded: (type: string, userToSyncWithHost: any) => {
        logger.warn('sendUserLoaded', userToSyncWithHost);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type,
                data: JSON.stringify(userToSyncWithHost),
            }),
        );
    },

    sendUserUnloaded: (type: string) => {
        window.chrome.webview.postMessage(
            JSON.stringify({
                type,
                data: null,
            }),
        );
    },

    sendWebviewSettings: (webviewSettingsEditMode: WebviewSettingsEditModeInterface) => {
        const webviewSettingsWithHost = {
            isShowToolbarInSlideshow: webviewSettingsEditMode.isShowToolbarInSlideshow,
            isShowClassCodeLabelInSlideshow: webviewSettingsEditMode.isShowClassCodeLabelInSlideshow,
            isShowAudienceSlideViewer: webviewSettingsEditMode.isShowAudienceSlideViewer,
            showSlideShowToolbarLabels: webviewSettingsEditMode.showSlideShowToolbarLabels,
            disableInkOverlay: webviewSettingsEditMode.disableInkOverlay,
        };
        logger.warn('webviewSettingsWithHost', webviewSettingsWithHost);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'webviewSettings',
                data: JSON.stringify(webviewSettingsWithHost),
            }),
        );
    },

    sendClassSession: (classSession: ClassSessionInterface) => {
        const classSessionForHost = {
            classCode: classSession.classCode,
            isSavedClass: !!classSession.classSessionName,
            isLocked: classSession.isLocked,
            participantCount: classSession.participantList.length,
            onlineParticipantCount: classSession.participantList.filter((p) => p.joined && !p.left).length,
            newQnaCount: classSession.qnaData?.qnas?.filter((qna) => !qna.isAnswered)?.length || 0,
        };
        logger.warn('classSessionForHost', classSessionForHost);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'classSessionUpdated',
                data: JSON.stringify(classSessionForHost),
            }),
        );
    },

    sendSlideImageUrl: (payload: { slideId: number; currentStep: number; imageUrl: string }) => {
        logger.warn('To Host: sendSlideImageUrl', payload);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'updateSlideImageUrl',
                data: JSON.stringify(payload),
            }),
        );
    },

    sendActivityOrVotingParticipantCount: (count: number) => {
        logger.warn('sendActivityOrVotingParticipantCount', count);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'activityOrVotingParticipantCount',
                data: count,
            }),
        );
    },

    sendSyncCountdown: (remainingSeconds: number) => {
        // logger.warn('sendSyncCountdown', remainingSeconds);
        window.chrome.webview.postMessage(JSON.stringify({ type: 'syncCountdown', data: remainingSeconds }));
    },

    sendCloseSubmission: (activityId: string, hasResponses: boolean) => {
        logger.warn('sendCloseSubmission', activityId);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'closeSubmission',
                data: JSON.stringify({
                    activityId,
                    hasResponses,
                }),
            }),
        );
    },

    sendVotingAction: (action: any) => {
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'votingAction',
                data: action,
            }),
        );
    },

    sendResetActivity: (activityId: string) => {
        logger.warn('To Host: sendResetActivity', activityId);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'resetActivity',
                data: activityId,
            }),
        );
    },

    sendResetActivityV2: (activityId: string, keepWindowOpen: boolean = false) => {
        logger.warn('To Host: sendResetActivityV2', { activityId, keepWindowOpen });
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'resetActivityV2',
                data: JSON.stringify({ activityId, keepWindowOpen }),
            }),
        );
    },

    sendRestartActivity: (oldActivityId: string, newActivityId: string) => {
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'restartActivity',
                data: JSON.stringify({
                    activityId: oldActivityId,
                    newActivityId,
                }),
            }),
        );
    },

    sendInsertWhiteboard: (imageData: string) => {
        logger.warn('sendInsertWhiteboard', imageData);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'insertWhiteboard',
                data: imageData,
            }),
        );
    },

    sendInsertMovableObject: (imageData: string) => {
        logger.warn('sendInsertMovableObject', imageData);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'insertMovableObject',
                data: imageData,
            }),
        );
    },

    sendSimpleMessage: (message: string) => {
        logger.warn('sendSimpleMessage() to host: ', message);
        window.chrome.webview.postMessage(JSON.stringify({ type: message, data: null }));
    },

    sendHideWebview: () => {
        logger.warn('webviewMessenger: sendHideWebview');
        window.chrome.webview.postMessage(JSON.stringify({ type: 'hideWebview', data: null }));
    },

    sendOpenBrowser: (url: any) => {
        logger.warn('webviewMessenger: sendOpenBrowser');
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'openBrowser',
                data: url,
            }),
        );
    },

    sendInsertAsSlide: (payload: any) => {
        logger.warn('webviewMessenger: sendInsertAsSlide', payload);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'insertSlide',
                data: payload,
            }),
        );
    },

    sendExportPdf: (selectedOnly: boolean, includeAnnotations: boolean, includeWhiteboard: boolean) => {
        logger.warn('webviewMessenger: sendExportPdf', {
            selectedOnly,
            includeAnnotations,
            includeWhiteboard,
        });
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'exportPdf',
                data: JSON.stringify({
                    selectedOnly,
                    includeAnnotations,
                    includeWhiteboard,
                }),
            }),
        );
    },

    sendNavigateToActivitySlide: (activityId: string) => {
        logger.warn('webviewMessenger: sendNavigateToActivitySlide', activityId);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'navigateToActivitySlide',
                data: activityId,
            }),
        );
    },

    sendCsv: (fileName: string, csvContent: string) => {
        logger.warn('webviewMessenger: sendCsv', { fileName, csvContent });
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'generateCsv',
                data: JSON.stringify({
                    fileName,
                    csvContent,
                }),
            }),
        );
    },

    timerDefaultSize: () => {
        logger.warn('webviewMessenger: timerDefaultSize');
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'defaultWebViewSize',
                data: null,
            }),
        );
    },

    timerFullScreenSize: () => {
        logger.warn('webviewMessenger: timerFullScreenSize');
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'fullScreenWebViewSize',
                data: null,
            }),
        );
    },

    timerCollapseDataToHost: (data: { isTimer: boolean; currentTime: number; isPaused: boolean }) => {
        logger.warn('webviewMessenger: timerDataToHost', data);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'timerData',
                data: JSON.stringify(data),
            }),
        );
    },

    openVideoTutuorial: (videoId: any) => {
        logger.warn('webviewMessenger: videoId', videoId);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'tutorial',
                data: videoId,
            }),
        );
    },

    // sendSyncWebviewInstances: (actionType: string) => {
    //     logger.warn('webviewMessenger: sendSyncWebviewInstances', actionType);
    //     window.chrome.webview.postMessage(
    //         JSON.stringify({
    //             type: 'syncWebviewInstances',
    //             data: JSON.stringify(actionType),
    //         }),
    //     );
    // },

    sendUpdatePassword: (password: string) => {
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'updatePassword',
                data: password,
            }),
        );
    },

    sendUpdateLanguage: (language: string) => {
        if (!isCpVerAtLeast('2.0.24')) return;
        logger.warn('webviewMessenger: sendUpdateLanguage', language);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'updateLanguage',
                data: language,
            }),
        );
    },

    sendHostLog: (type: HostLogType, message: string) => {
        if (!isCpVerAtLeast('2.0.30')) return;
        const fullLog = `<WEBVIEW_TO_HOST> [${type}] ${message}`;
        logger.warn(`sendHostLog: ${fullLog}`);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'sendWebviewLogToHost',
                data: fullLog,
            }),
        );
    },

    sendReconnectPresenter: () => {
        const message = isCpVerAtLeast('2.0.30') ? 'reconnectPresenter' : 'startSlideshowAgain';
        logger.warn(`webviewMessenger: ${message}`);
        window.chrome.webview.postMessage(JSON.stringify({ type: message, data: null }));
    },

    sendUsageLog: (message: string) => {
        if (!isCpVerAtLeast('2.0.33')) return;
        logger.warn(`sendUsageLog: ${message}`);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'sendUserActionToHost',
                data: message,
            }),
        );
    },

    enterSlideshow: (data: string | null) => {
        if (!isCpVerAtLeast('2.0.39')) return;
        logger.warn(`webviewMessenger : enterSlideshow`);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'enterSlideshow',
                data,
            }),
        );
    },

    openQuizExamples: () => {
        if (!isCpVerAtLeast('2.0.39')) return;
        logger.warn(`webviewMessenger : openQuizExamples`);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'openQuizExamples',
                data: null,
            }),
        );
    },

    sendCheckForUpdate: () => {
        if (!isCpVerAtLeast('2.0.39')) return;
        logger.warn(`webviewMessenger : sendCheckForUpdate`);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'checkUpdate',
                data: null,
            }),
        );
    },

    insertAiQuizSlide: (payload: AiQuizSlideInterface) => {
        if (!isCpVerAtLeast('2.0.42')) return;
        logger.warn('webviewMessenger: insertAiQuizSlide', payload);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'insertAiQuizSlide',
                data: JSON.stringify(payload),
            }),
        );
    },

    insertTemplate: (pptUrl: string) => {
        if (!isCpVerAtLeast('2.4.0')) return;
        logger.warn('webviewMessenger: insertTemplate', pptUrl);
        window.chrome.webview.postMessage(
            JSON.stringify({
                type: 'insertTemplate',
                data: JSON.stringify({ pptUrl }),
            }),
        );
    },
};

export default webviewMessenger;
