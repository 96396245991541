import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Checkbox, Divider, FormControlLabel, FormGroup, Typography } from '@mui/material';

import webviewMessenger from '../../../services/webviewMessenger';
import { webviewSettingsActions } from '../../../actions/webview-settings.actions';

import StoreInterface from '../../../interfaces/store.interface';
import { utilConstants } from '../../../constants/utils.constants';
import { WebviewSettingsActionTypes } from '../../../constants/webview-settings-action-types';
import apiPreferences from '../../../services/apiPreferences';
// import { isUserOnPremium } from '../../../helpers/userhelper';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import { determineQnaToggle, isInknoeUser, isUserOnTrialOrPro } from '../../../helpers/userhelper';
import { userActions } from '../../../actions/user.actions';
import { UserActionTypes } from '../../../constants/user-action-types';
import apiClassSessions from '../../../services/apiClassSessions';

const Slideshow = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state: StoreInterface) => state).user;
    const webviewSettingsEditMode = useSelector((state: StoreInterface) => state).webviewSettingsEditMode;

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [enableQna, setEnableQna] = useState(!user ? false : determineQnaToggle(user));

    useEffect(() => {
        webviewMessenger.sendUsageLog(`Navigated to Settings -> Slideshow`);
    }, []);

    const handleToggleClassCodeLabel = (event: ChangeEvent<HTMLInputElement>) => {
        const willShowClassCodeLabel = event.target.checked;
        dispatch(
            webviewSettingsActions.updateWebviewSettings(
                WebviewSettingsActionTypes.TOGGLE_SHOW_CLASS_CODE_LABEL_IN_SLIDESHOW,
                {
                    isShowClassCodeLabelInSlideshow: willShowClassCodeLabel,
                },
            ),
        );
        webviewMessenger.sendWebviewSettings({
            ...webviewSettingsEditMode,
            isShowClassCodeLabelInSlideshow: willShowClassCodeLabel,
        });
        webviewMessenger.sendUsageLog(`Toggle show class code: ${willShowClassCodeLabel ? 'ON' : 'OFF'}`);
    };

    const handleToggleToolbar = (event: ChangeEvent<HTMLInputElement>) => {
        const willShowToolbar = event.target.checked;
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.TOGGLE_SHOW_TOOLBAR_IN_SLIDESHOW, {
                isShowToolbarInSlideshow: willShowToolbar,
            }),
        );
        webviewMessenger.sendWebviewSettings({
            ...webviewSettingsEditMode,
            isShowToolbarInSlideshow: willShowToolbar,
        });
        webviewMessenger.sendUsageLog(`Toggle show toolbar: ${willShowToolbar ? 'ON' : 'OFF'}`);
    };

    const handleToggleInkOverlay = (event: ChangeEvent<HTMLInputElement>) => {
        const enableInkOverlay = event.target.checked;
        const disableInkOverlay = !enableInkOverlay;
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.TOOGLE_INK_OVERLAY, {
                disableInkOverlay,
            }),
        );
        webviewMessenger.sendWebviewSettings({
            ...webviewSettingsEditMode,
            disableInkOverlay,
        });
        webviewMessenger.sendUsageLog(`Toggle ink overlay: ${enableInkOverlay ? 'ON' : 'OFF'}`);
    };

    const handleToggleToolbarLabels = (event: ChangeEvent<HTMLInputElement>) => {
        const willShowLabels = event.target.checked;
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.TOGGLE_SHOW_TOOLBAR_LABELS, {
                showSlideShowToolbarLabels: willShowLabels,
            }),
        );
        webviewMessenger.sendWebviewSettings({
            ...webviewSettingsEditMode,
            showSlideShowToolbarLabels: willShowLabels,
        });
        webviewMessenger.sendUsageLog(`Toggle show toolbar labels: ${willShowLabels ? 'ON' : 'OFF'}`);
    };

    const handleToggleSlideViewer = (event: ChangeEvent<HTMLInputElement>) => {
        const willShowSlideViewer = event.target.checked;
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.TOGGLE_AUDIENCE_SLIDE_VIEWER, {
                isShowAudienceSlideViewer: willShowSlideViewer,
            }),
        );
        webviewMessenger.sendWebviewSettings({
            ...webviewSettingsEditMode,
            isShowAudienceSlideViewer: willShowSlideViewer,
        });
        webviewMessenger.sendUsageLog(`Toggle show slide viewer: ${willShowSlideViewer ? 'ON' : 'OFF'}`);
    };

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const handleToggleQna = async (event: ChangeEvent<HTMLInputElement>) => {
        setEnableQna((prev) => !prev);
        const isEnable = event.target.checked;
        if (user) {
            const updatedUser = await apiPreferences.toggleQna(user.email, isEnable);
            if (updatedUser) {
                dispatch(userActions.updateUser(UserActionTypes.TOGGLE_QNA, updatedUser));
                // notify class session app
                if (user.userClassSession?.cpcsRegion)
                    apiClassSessions.refetchPresenter(user.userClassSession?.cpcsRegion, user.email);
            }
        }
    };

    return (
        <Fragment>
            <div className="body_head">
                <Typography variant="h2">{t('lang_slide_show_settings.title')}</Typography>
            </div>
            <div className="slideshow_sec">
                <div className="dynamic_slide_img">
                    <img src={utilConstants.IMAGE_URLS.Slide1} alt="Slide1" />

                    {webviewSettingsEditMode.isShowToolbarInSlideshow && (
                        <div className="toolbar">
                            <img src={utilConstants.IMAGE_URLS.toolbar} alt="toolbar" />
                        </div>
                    )}
                    {webviewSettingsEditMode.isShowClassCodeLabelInSlideshow && (
                        <div className="sidecode">
                            <img src={utilConstants.IMAGE_URLS.ClassCode} alt="classCode" />
                        </div>
                    )}
                </div>
                <div className="slide_option">
                    <FormGroup>
                        <div className="checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleToggleClassCodeLabel}
                                        checked={webviewSettingsEditMode.isShowClassCodeLabelInSlideshow}
                                    />
                                }
                                label={t('lang_slide_show_settings.chk_show_class_code_presentation')}
                            />
                        </div>
                        <div className="checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={webviewSettingsEditMode.isShowToolbarInSlideshow}
                                        onChange={handleToggleToolbar}
                                    />
                                }
                                label={t('lang_slide_show_settings.chk_display_toolbar')}
                            />
                        </div>
                        <div className="checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!webviewSettingsEditMode.disableInkOverlay}
                                        onChange={handleToggleInkOverlay}
                                    />
                                }
                                label={t('lang_slide_show_settings.chk_ink_overlay')}
                            />
                        </div>
                        <div className="checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={webviewSettingsEditMode.showSlideShowToolbarLabels}
                                        onChange={handleToggleToolbarLabels}
                                    />
                                }
                                label={t('lang_slide_show_settings.chk_show_toolbar_labels')}
                            />
                        </div>
                        <Divider />
                        <div className="checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={webviewSettingsEditMode.isShowAudienceSlideViewer}
                                        onChange={handleToggleSlideViewer}
                                    />
                                }
                                label={t('lang_slide_show_settings.chk_enable_audience_slide_viewer')}
                            />
                            <Typography variant="caption" className="caption">
                                {t('lang_slide_show_settings.chk_enable_audience_slide_viewer_summary')}
                            </Typography>
                        </div>
                        {/* {user && isUserOnTrialOrPro(user) && isInknoeUser(user.email) && (
                            // {user && isUserOnPremium(user) && (
                            <div className="checkbox qnaOption">
                                <FormControlLabel
                                    control={<Checkbox checked={enableQna} onChange={handleToggleQna} />}
                                    label={t('lang_slide_show_settings.chk_enable_qna')}
                                />
                                <Typography variant="caption" className="caption">
                                    {t('lang_slide_show_settings.chk_enable_qna_description')}
                                </Typography>
                            </div>
                        )} */}
                    </FormGroup>
                </div>
            </div>
        </Fragment>
    );
};

export default Slideshow;
